/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import HeaderMobile from "../headerNew"
import Header from "../header"
import Footer from "../../footer"
import "../Layouts/layout.css"


const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div>
      <Header />
      <HeaderMobile />
      <div
        style={{
          margin: `110px auto 0 auto`,
          maxWidth: 1440,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-XF1GKXN75R"></script> 

      <script> {` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-XF1GKXN75R');`} </script>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
